import React from 'react'
import './Auth.css'

export default function LoginPage() {
  return (
    <div className='row'>
      <div className='col-12' style={{height:'70px'}} >
        &nbsp;
      </div>
        <div className='col-12' >
          <h1 style={{color:'whitesmoke'}} >GeoCrafts</h1>
        </div>
        <div className='col-12'  >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Oops!</h5>
            <p className="card-text">Our site is currently getting a little makeover. We're working hard to finish quickly. Can we welcome you back a bit later?</p>
            <a href="https://www.youtube.com/watch?v=0Wi8Fv0AJA4" className="btn btn-primary"  >In the meantime, enjoy this fun tune while you wait! 🎶 Jeopardy! Theme Song</a>
          </div>
        </div>
        </div>
    </div>
  )
}
