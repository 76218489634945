import LoginPage from "./Pages/Auth/LoginPage";
import './index.css'

function App() {
  return (
    <div style={{width:'100%',height:'100vh'}}>
      <div className="container-xl">
        <LoginPage />
      </div>
    </div>
    
  );
}

export default App;
